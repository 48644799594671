<template lang="pug">
div.v-player-settings.panel
  div.v-player-settings__item(v-if="isMicOn" @click="toggleMic")
    v-icon.icon(icon="MicIcon" size="24")
  div.v-player-settings__item(v-if="!isMicOn" @click="toggleMic")
    v-icon.icon(icon="MicOffIcon" size="24")
  div.v-player-settings__item(v-if="isVideo" @click="toggleVideo")
    v-icon.icon(icon="VideoIcon" size="24")
  div.v-player-settings__item(v-if="!isVideo" @click="toggleVideo")
    v-icon.icon(icon="VideoOffIcon" size="24")
  div.v-player-settings__item(v-if="isMobileDevice" @click="toggleChatPanel")
    v-icon.icon(icon="MessageCircleIcon" size="24")
  div.v-player-settings__item(@click="$emit('show-settings')")
    v-icon.icon(icon="SettingsIcon" size="24")
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'VPlayerSettings',
  data() {
    return {
      isMic: true,
      isVideo: true,
    }
  },
  computed: {
    ...mapGetters('devices', ['isMicOn']),
    isMobileDevice() {
      return this.$isMobile
    },
  },
  methods: {
    toggleMic() {
      this.$emit('on-mic-state-change', !this.isMicOn)
    },
    toggleVideo() {
      this.isVideo = !this.isVideo
      this.$emit('on-video-state-change', this.isVideo)
    },
    toggleChatPanel() {
      this.$emit('toggle-chat-panel')
    },
  },
}
</script>

<style lang="scss">
.v-player-settings {
  &.panel {
    display: flex;
  }
  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }

  @media (max-width: 800px) {
    height: 54px;
    &.panel {
      justify-content: space-around;
    }
  }
}
</style>
